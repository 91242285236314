const App = () => (
  <div className="App">
    <iframe
      scrolling="no"
      src="https://startup.hanyang.ac.kr/"
      style={{
        border: "none",
        display: "block",
        height: "100dvh",
        width: "100dvw",
      }}
      title="startup.hanyang.ac.kr"
    />
  </div>
);

export default App;
